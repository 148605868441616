<template>
  <div class="profile">
    <div class="container">
      <b-row>
        <b-col md="6">
          <template v-if="$options.components['profile-show-edit-profile']">
            <profile-show-edit-profile />
          </template>
        </b-col>
        <b-col md="6">
          <h5 class="heading-referral mt-3">My Referrals</h5>
          <b-container class="mt-2 profile-maindv">
            <b-row class="w-200">
              <b-col>
                <b-card-text class="heading">Direct Referrals</b-card-text>
              </b-col>
              <b-col>
                <b-card-text class="heading margin-left">{{
                  myReferrals.directCount
                }}</b-card-text>
              </b-col>
            </b-row>
            <hr class="mt-4 mb-4" />
            <b-row class="w-200">
              <b-col>
                <b-card-text class="heading">Group referrals</b-card-text>
              </b-col>
              <b-col>
                <b-card-text class="heading margin-left">{{
                  myReferrals.groupCount
                }}</b-card-text>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "My-Referrals",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      myReferrals: (state) => state.profile.myReferrals,
      userInfo: (state) => state.auth.userInfo,
    }),
  },
  async mounted() {
    const userId = this.userInfo._id;
    await this.$store.dispatch("profile/fetchMyReferrals", userId);
  },
  methods: {},
};
</script>

<style scoped>
.profile-maindv {
  background: #f8f8f8;
  padding: 15px;
  border-radius: 15px;
  border: 1px solid #efefef;
  width: 100%;
}
.margin-left {
  margin-left: 180px;
}
.heading-referral {
  font-weight: 600;
  font-size: 18px;
}
.heading {
  font-weight: 600;
  font-size: 16px;
}
</style>

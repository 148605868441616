var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "profile"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_vm.$options.components['profile-show-edit-profile'] ? [_c('profile-show-edit-profile')] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('h5', {
    staticClass: "heading-referral mt-3"
  }, [_vm._v("My Referrals")]), _c('b-container', {
    staticClass: "mt-2 profile-maindv"
  }, [_c('b-row', {
    staticClass: "w-200"
  }, [_c('b-col', [_c('b-card-text', {
    staticClass: "heading"
  }, [_vm._v("Direct Referrals")])], 1), _c('b-col', [_c('b-card-text', {
    staticClass: "heading margin-left"
  }, [_vm._v(_vm._s(_vm.myReferrals.directCount))])], 1)], 1), _c('hr', {
    staticClass: "mt-4 mb-4"
  }), _c('b-row', {
    staticClass: "w-200"
  }, [_c('b-col', [_c('b-card-text', {
    staticClass: "heading"
  }, [_vm._v("Group referrals")])], 1), _c('b-col', [_c('b-card-text', {
    staticClass: "heading margin-left"
  }, [_vm._v(_vm._s(_vm.myReferrals.groupCount))])], 1)], 1)], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }